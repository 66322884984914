<template>
  <div class="service-center clearfix">
    <!--        客服中心-->
    <div class="title">
      <p>客服中心</p>
      <p>CUSTOMER</p>
    </div>
    <div class="air-wall"></div>
    <!--        在线咨询-->
    <div class="online-inquiry">
      <div class="zixun">
        <span>在线咨询</span>&nbsp;
        <!--                div可以作换行符！！！-->
        <a class="qq-btn" :href="`http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=${shopInfo.service_qq1}`"
           target="_blank">
          <img src="../assets/images/goods-detail/detailed_icon5.png" alt="">
          <span>立即咨询</span>
        </a>
        <span>{{ shopInfo.service_name1 }}</span>
      </div>
    </div>
    <div class="online-inquiry">
      <div class="zixun">
        <span>在线咨询</span>&nbsp;
        <!--                div可以作换行符！！！-->
        <a class="qq-btn" :href="`http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=${shopInfo.service_qq2}`"
           target="_blank">
          <img src="../assets/images/goods-detail/detailed_icon5.png" alt="">
          <span>立即咨询</span>
        </a>
        <span>{{ shopInfo.service_name2 }}</span>
      </div>
    </div>
    <!--        在线客服-->
    <div class="service">
      <span>在线客服</span>

      <el-button class="service-btn" size="small" icon="el-icon-phone" @click="serviceLink">在线客服</el-button>

    </div>
    <!--        在线时间-->
    <div class="timer">
      <span>工作时间 A M 8:00 - P M 18:00</span>

    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerServiceCenter",
  props: {
    shopInfo: {
      type: Object,
      default: {},
    },
    serviceLink: {
      type: Function,
    }
  }
}
</script>

<style scoped lang="scss">
.service-btn {
  &:hover,
  &:focus {
    background-color: white;
    border-color: lightgrey;
    color: #4e4e4d;
  }
}

.service-center {
  width: 198px;
  height: 315px;
  background: white;
  float: left;
  //大标题
  .title {
    display: flex;
    flex-direction: column;
    //交叉轴对齐
    align-items: center;
    //主轴对齐
    justify-content: center;
    padding: 29px 51px;

    :nth-child(1) {
      width: 96px;
      height: 24px;
      font-size: 24px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }

    :nth-child(2) {
      width: 75px;
      height: 14px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      margin-top: 8px;
    }
  }

  //空气墙
  .air-wall {
    width: inherit;
    height: 30px;
    opacity: 0.7;
    border-top: 1px solid #D8D8D8;;
    border-bottom: 1px solid #D8D8D8;;
  }

  .online-inquiry {
    opacity: 0.7;
    padding: 9px;

    .zixun {
      display: flex;
      justify-content: space-around;
      align-items: center;
      //咨询里的span都得给爷小！！！
      span {
        font-size: 12px;
        line-height: 1;
      }

      .qq-btn {
        cursor: pointer;
        width: 82px;
        height: 23px;
        background: #E3F6FE;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

  //在线客服
  .service {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 5px;
    height: 40px;
  }

  //在线时间
  .timer {

    cursor: pointer;
    text-align: center;

    span {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
    }
  }
}
</style>