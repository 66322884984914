<template>
  <div class="goods-history" v-loading="loading">
    <div style="margin: 0 auto;">
      <div class="line"></div>
      <h4>看了又看</h4>
      <div class="line"></div>
    </div>

    <el-carousel height="480px" arrow="never" style="width: 120px;" indicator-position="outside">

      <el-carousel-item v-for="(item,index) in list" style="height:100%;width: 100%">
        <li v-for="(InnerItem, index) in item" :key="index"
            @click="$router.pushToTab({ path: '/sku-' + InnerItem.sku_id })" style="position: relative;">
          <div class="img-wrap">
            <img :src="$img(InnerItem['sku_image'], { size: 'mid' })"/>
            <div class="masking">{{ InnerItem.goods_name }}</div>
          </div>
          <div class="price">￥{{ InnerItem.discount_price }}</div>
        </li>
      </el-carousel-item>

    </el-carousel>
  </div>

</template>

<script>
import {mapGetters} from "vuex"
import {goodsRecommend} from "../api/goods/goods"
import UseGrouping from "@/hooks/UseGrouping"
import http from "@/utils/http"

export default {
  name: "goods_history",
  props: {
    page: {
      type: [Number, String],
      default: 1
    },
    pageSize: {
      type: [Number, String],
      default: 9
    }
  },
  data: () => {
    return {
      loading: true,
      list: []
    }
  },
  created() {
    this.getGoodsRecommend()
  },
  computed: {
    ...mapGetters(["defaultGoodsImage"])
  },
  methods: {
    getGoodsRecommend() {
      http({
            url: "/api/goodsbrowse/page",
            data: {
              page: this.page,
              page_size: this.pageSize
            }
          }
      ).then(res => {
        if (res.code == 0) {
          this.list = res.data.list
          // 对数组进行分组
          this.list = UseGrouping(3, this.list)
        }
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },

    imageError(index) {
      this.list[index].sku_image = this.defaultGoodsImage
    }
  }
}
</script>

<style lang="scss">
.goods-history {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  width: 200px;
  background: #FFFFFF;

  .line {
    display: inline-block;
    width: 60px;
    height: 1px;
    background: #D8D8D8;
  }

  h4 {
    display: inline-block;
    margin: 0 10px;
  }


  li {
    padding-top: 10px;
    cursor: pointer;

    .img-wrap {
      width: 100%;
      height: 100%;
      position: relative;
      //蒙版
      .masking {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background: #FFFFFF;
        opacity: 0.9;
        padding: 0 5px;
      }
    }

    .price {
      text-align: center;
      line-height: 1;
      margin-top: 9px;
      color: $base-color;
      font-size: 16px;
    }


    &:hover {
      color: $base-color;
    }
  }


  .el-carousel__button {
    width: 9px;
    height: 9px;
    background: #000000;
    opacity: 0.1;
    border-radius: 100%;
  }

  .el-carousel__indicators--outside .el-carousel__indicator:hover button {
    opacity: 0.3;
  }

}

</style>
